import React, { Component } from 'react';
import { Accordion, AccordionItem } from 'react-sanfona';
import Switch from "react-switch";

import './App.scss';

// const API = 'http://192.168.1.123:8000/api';
const API = 'http://api.si.lml.ae/api';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      initialItems: [],
      items: [],
      item: {
        name: '',
        organization: ''
      },
      showItems: false,
      searchTerm: '',
    };

    this.filterList = this.filterList.bind(this);
    this.setDrinksCollected = this.setDrinksCollected.bind(this);
    this.setVoucherCollected = this.setVoucherCollected.bind(this);
    this.setCheckIn = this.setCheckIn.bind(this);
  }

  componentWillMount() {
    console.log('Loading data');

    fetch(`${API}/gala`)
      .then(response => response.json())
      .then(data => {
        console.log('LOADED::', data);
        this.setState({
          initialItems: data,
          // items: data
        });
      });
  }

  componentDidMount() {
    // this.setState({ items: this.state.initialItems });
  }

  setDrinksCollected(item) {
    console.log('Voucher', item);

    item.drinks_collected = 1;

    let newState = Object.assign({}, this.state);
    const index = newState.initialItems.map(e => e.id).indexOf(item.id);
    // newState.initialItems[index].checked_in = 1;
    newState.initialItems[index] = item;
    this.setState(
      newState
    );
  }

  setVoucherCollected(item) {
    console.log('Voucher', item);

    item.raffle_collected = 1;

    let newState = Object.assign({}, this.state);
    const index = newState.initialItems.map(e => e.id).indexOf(item.id);
    // newState.initialItems[index].checked_in = 1;
    newState.initialItems[index] = item;
    this.setState(
      newState
    );
  }

  setCheckIn(item) {
    console.log('Voucher', item);

    item.checked_in = 1;

    let newState = Object.assign({}, this.state);
    const index = newState.initialItems.map(e => e.id).indexOf(item.id);
    newState.initialItems[index] = item;
    this.setState(
      newState
    );

    fetch(`${API}/gala/checkin/${item.id}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // method: "POST",
        // body: JSON.stringify(item)
      })
      .then(function (res) { console.log(res) })
      .catch(function (res) { console.log(res) })
  }

  filterList(e) {
    console.log(e.target.value);
    const showItems = (e.target.value.length > 3)
    this.setState({
      // showItems: showItems,
      searchTerm: e.target.value
    })
    if ((e.target.value.length > 2)) {
      console.log('YES')
      var updatedList = this.state.initialItems;
      updatedList = updatedList.filter(function (item) {
        return item.name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 ||
          item.organization.toLowerCase().search(
            e.target.value.toLowerCase()) !== -1 ||
          item.hrms.search(
            e.target.value.toLowerCase()) !== -1;
      });
      this.setState({ items: updatedList });
    }
    else {
      console.log('NO')
      this.setState({ items: [] });
    }
  }

  renderItems = () => {
    return (
      <Accordion>
        {this.state.items.map((item, i) => {
          return (
            <AccordionItem title={`${item.name}`} expanded={item.id === 1} key={item.id} className={item.checked_in == 1 ? 'checked' : ''}>
              <div>
                <h4>HRMS: {item.hrms}</h4>
                <h4>Business Group: {item.business_group}</h4>
                <h4>Cost Center: {item.organization}</h4>
                <h4>Region: {item.region}</h4>
                <div className='form'>
                  {/* <label htmlFor="raffle-switch">
                    <span>Raffle Voucher Collected</span>
                    <Switch
                      onChange={() => this.setVoucherCollected(item)}
                      checked={!!item.raffle_collected}
                      id="raffle-switch"
                      disabled={!!item.voucher_eligible}
                    />
                  </label>
                  <label htmlFor="drinks-switch">
                    <span>Drink Voucher Collected</span>
                    <Switch
                      onChange={() => this.setDrinksCollected(item)}
                      checked={!!item.drinks_collected}
                      id="drinks-switch"
                    />
                  </label> */}
                  <label htmlFor="checkin">
                    <button id="checkin" onClick={() => this.setCheckIn(item)} disabled={item.checked_in == 1}>Check In</button>
                  </label>
                  <label htmlFor="voucher" className={item.voucher_eligible == 0 ? 'visible' : 'hidden'}>
                    Not Eligible for Voucher
                  </label>
                </div>
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    )
  }

  render() {

    if (this.state.initialItems.length <= 0) {
      return (
        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <h2>Loading...</h2>
        </div>
      )
    }

    if (this.state.items.length <= 0) {
      return (
        <div className="filter-list">
          <input type="tel" placeholder="Search" onChange={this.filterList} />
          <h2>Start typing to search...</h2>
        </div>
      )
    }

    return (
      <div className="filter-list">
        <input type="tel" placeholder="Search" onChange={this.filterList} />
        {this.renderItems()}
      </div>
    );
  }
}


export default App;
